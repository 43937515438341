<template>
  <div>

    <b-row>
      <b-col 
        xl="8" cols="12"
      >
        <b-card class="d-flex align-items-center">

          <b-card-body class="pt-4 pb-4">

            <b-row>
              <b-col>
                <h1>Escolha <strong>uma opção</strong></h1>
                <p>Selecione a forma como você deseja recarregar</p>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              
              <b-col
                xl="6"
                cols="12"
              >
                
                <b-link
                  v-bind:to='assinaturaAtiva === true ? undefined : "/financeiro/assinatura/planos"'
                  v-on:click="assinaturaAtiva === false ? undefined : alertaAssinaturaAtiva()"
                >

                  <b-card
                    border-variant="secondary"
                    bg-variant="transparent"
                    class="selecionarPagamento text-center"
                  >
                  <b-card-body>
                    <h2>
                      <feather-icon
                        icon="RefreshCwIcon"
                        size="22"
                      />
                    </h2>
                    <h6>Assinatura <br>(Cartão de Crédito)</h6>
                    <b-badge pill variant="primary" class="badge-glow">Recomendado</b-badge>
                  </b-card-body>
                  </b-card>

                </b-link>

              </b-col>

              <b-col
                xl="6"
                cols="12"
              >
                
                <b-link
                  :to="{ name: 'financeiro-recarregar-planos'}"
                >

                  <b-card
                    border-variant="secondary"
                    bg-variant="transparent"
                    class="selecionarPagamento text-center"
                  >
                  <b-card-body>
                    <h2>
                      <b-icon
                        icon="wallet2"
                      />
                    </h2>
                    <h6>Adicionar Saldo <br>(Pix)</h6>
                    <b-badge pill variant="secondary" class="badge-glow">Pague por cada crédito</b-badge>
                  </b-card-body>
                  </b-card>

                </b-link>

              </b-col>

            </b-row>

          </b-card-body>
            
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BButton, BBadge, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BBadge,
    BLink,
  },
  data() {
    return {
      assinaturaAtiva: null,
    }
  },
  computed: {
   
  },
  created() {
  },
  mounted() {

    // Verificar Assinatura
    const usuarioData = JSON.parse(localStorage.getItem('usuarioData'))
    this.assinaturaAtiva = usuarioData.assinaturaAtiva

    // Verificar Assinatura

  },
  methods: {
    alertaAssinaturaAtiva() {
      
      this.$swal({
        title: 'Ops! Aconteceu um erro.',
        text: 'Você já tem uma assinatura ativa.',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })

    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';

.card.selecionarPagamento:hover {
  box-shadow: 0 0 5px 1px rgb(254 152 86 / 40%) !important;
  border-color: #ff7300 !important;
}


</style>
